import SEO from '@components/Common/SEO/index';
import useResizer from '@components/extra/useResizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import * as styles from './CrioReboot.module.scss';
import { graphql } from 'gatsby';
import FooterV2 from '@components/FooterV2/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import 'swiper/css';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';

const scheduleData = [
  {
    title: 'Power Session',
    subTitle: 'Top must-have skills for an Automation Engineer',
    description:
      'Get familiar with the top skills necessary to be a competent Automation Engineer',
    dateAndDuration: [
      'June 17th, 2023',
      'Fireside Chat - 12:00 PM to 12:40 PM',
      'QA Power Session : 12:45 PM to 1:45 PM',
    ],
    speakers: ['Rathinamurthy R', 'Saravana'],
  },
  {
    title: 'Power Session',
    subTitle: 'Performance Testing of Flipkart',
    description: 'Experience performance testing on a Flipkart-like system',
    dateAndDuration: [
      'June 24th, 2023',
      'Fireside Chat - 12:00 PM to 12:40 PM',
      'QA Power Session : 12:45 PM to 1:45 PM',
    ],
    speakers: ['Rathinamurthy R', 'Ajitav'],
  },
  {
    title: 'Power Session',
    subTitle: 'DevOps for Automation Engineers',
    description: 'Decode the role of Automation Engineers in DevOps',
    dateAndDuration: [
      'July 1st, 2023',
      'Fireside Chat - 12:00 PM to 12:40 PM',
      'QA Power Session : 12:45 PM to 1:45 PM',
    ],
    speakers: ['Rathinamurthy R', 'Praveen'],
  },
];

export default function AutomationEdge({ data }) {
  const images = data.images.nodes;

  const [isOpen, setOpen] = useState(false);
  const [navMobile, setNavMobile] = useState(false);
  const [animation, setAnimation] = useState('false');
  const [activeScheduleIndex, setActiveScheduleIndex] = useState(0);

  const wrapperRef = useRef();

  useEffect(() => {
    if (isOpen) {
      let myIframe = document.getElementById('cwod-video');
      myIframe.src =
        'https://www.youtube.com/embed/VdN6Jw4-3Dc?autoplay=1&rel=0&enablejsapi=1&origin=https%3A%2F%2Fcrio.do';
    }
  }, [isOpen]);
  useOnClickOutside(wrapperRef, () => {
    if (isOpen) {
      setOpen(false);
    }
  });

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  }

  function getSchedule(activeScheduleIndex) {
    switch (activeScheduleIndex) {
      case 0:
        return (
          <div className="flex flex-col items-center justify-center gap-4 md:w-[916px] md:flex-row">
            <div className="max-w-[400px] rounded-md bg-[#009378] p-4">
              <div className="" id={styles.content}>
                <p className="mt-6 mb-4">
                  Fireside chat with industry leaders discussing the evolving
                  industry, and emerging opportunities in automation
                </p>
              </div>
              <div className={styles.scheduleBox}>
                <h6 className={styles.scheduleBoxHeading}>Dates & Duration</h6>{' '}
                <ul>
                  <li>June 10th, 2023</li>
                  <li>Fireside Chat - 12:00 PM to 12:40 PM</li>
                  <li>QA Power Session : 12:45 PM to 1:45 PM</li>
                </ul>
              </div>
              <div className={`${styles.scheduleBox} !mb-0 min-h-[170px]`}>
                <h6 className={styles.scheduleBoxHeading}>Speakers</h6>{' '}
                <ul>
                  <li>Rathinamurthy R</li>
                  <li>Ajitav</li>
                </ul>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="flex flex-col items-center  justify-center gap-2 md:w-[916px]">
            <h3 className="mb-4 w-[300px] text-center text-base md:w-auto md:text-2xl">
              Interview preparation-focused learn by doing workshops
            </h3>
            <div className="flex flex-col gap-8 md:flex-row">
              <div className="max-w-[300px] rounded-md bg-[#004246] p-4">
                <div className=" md:h-[100px]" id={styles.content}>
                  <p className="mb-4">
                    Understand the fundamentals of Fullstack development and
                    build a portfolio to showcase your skills
                  </p>
                </div>
                <div className={`${styles.scheduleBox} !mb-0 mt-10`}>
                  <h6 className={styles.scheduleBoxHeading}>
                    Dates & Duration
                  </h6>{' '}
                  <ul>
                    <li>Dec 11th</li>
                    <li>12 pm to 1:30 pm</li>
                    <li>Fully remote, Online</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  }

  const options = (
    <ul
      onClick={() => {
        setAnimation('animate__slideOutDown');
        setTimeout(() => {
          setNavMobile(false);
        }, 700);
      }}
      className="-mt-1 flex w-screen flex-col justify-center bg-[#004246] py-4 pl-0 text-white md:flex-row"
    >
      <li>
        <Link to="/" className="">
          Crio.Do
        </Link>
      </li>
      <li>
        {' '}
        <a href="#about">About</a>
      </li>
      <li style={{ cursor: 'default' }}>
        <StaticImage
          placeholder="blurred"
          src="../../images/crio-reboot/Logo.webp"
          alt="Beaver"
          loading="lazy"
          className="scale-125"
          style={{ width: '100px' }}
        ></StaticImage>
      </li>
      <li>
        {' '}
        <a href="#schedule">Schedule</a>
      </li>

      <li>
        <a href="#what-you-get">What You Get</a>
      </li>
    </ul>
  );

  useEffect(() => {
    document.body.style['scroll-padding-top'] = '80px';
  }, []);

  return (
    <span className="">
      <SEO
        canonicalUrl={'https://crio.do/automation-edge'}
        title="Automation Edge 2023 - - Career Conclave focused on Aspiring QA Automation(SDET) Professionals"
        description="Join our exclusive sessions with industry experts and gain practical career success strategies in the rapidly evolving QA automation industry. Get hands-on experience with the latest tips and tools for QA automation and stand a chance to win exciting scholarships. Don't miss out on this opportunity to enhance your skills and stay ahead in the competitive world of QA automation."
      ></SEO>
      {navMobile && (
        <div
          className={`${styles.navMobile} ${animation} flex flex-col items-center  bg-[#004246] text-center`}
        >
          <div className="" style={{ zIndex: '2' }}>
            {' '}
            <FontAwesomeIcon
              icon={['fal', 'times']}
              size="2x"
              className="cursor-pointer"
              onClick={() => {
                setAnimation('animate__slideOutDown');
                setTimeout(() => {
                  setNavMobile(false);
                }, 700);
              }}
            />
            {options}
          </div>{' '}
        </div>
      )}
      <div
        className={` w-full justify-center py-2 pt-3 ${styles.headerMobile}`}
        data-aos="slide-down"
        data-aos-delay="800"
        data-aos-duration="1000"
        style={{ position: 'absolute', zIndex: '9' }}
      >
        <StaticImage
          placeholder="blurred"
          src="../../images/crio-reboot/Logo.webp"
          alt="Beaver"
          loading="lazy"
          style={{ width: '100px' }}
        ></StaticImage>

        <div
          onClick={() => {
            setNavMobile(true);
            setAnimation('animate__slideInUp');
          }}
          style={{
            position: 'absolute',
            zIndex: '9',
            right: '0',
            marginRight: '20px',
          }}
        >
          <FontAwesomeIcon
            icon={['fas', 'bars']}
            size="2x"
            className="cursor-pointer text-white"
          />
        </div>
      </div>
      <header
        data-aos="slide-down"
        data-aos-delay="800"
        data-aos-duration="1000"
        className={`${styles.header} justify-center`}
      >
        {options}
      </header>
      <div className="relative">
        <div
          className={`flex flex-col justify-center gap-2 bg-[#009378] pb-0 md:flex-row ${styles.wodHero}`}
        >
          <div className="m-0 h-full w-full md:w-1/2">
            <div className="flex flex-col items-center justify-center text-center md:items-start md:text-left">
              <div
                className="bodyText2 flex items-center justify-center gap-2 font-bold text-white md:justify-start"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>Industry Experts</div>
                <span className="text-xs">●</span>
                <div>Power Sessions</div>
                <span className="text-xs">●</span>
                <div>Exciting Scholarships</div>
              </div>
              <div className="mt-10 mb-3 md:mt-3" style={{ color: '#06131F' }}>
                <div className="mb-6">
                  <h1
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className="mb-3 text-3xl leading-tight text-white md:text-5xl"
                  >
                    Automation Edge 2023
                  </h1>
                </div>
                <p
                  className="font-bold text-white"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  A month long Career Conclave focused on Aspiring QA
                  Automation(SDET) Professionals
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  className="my-4 text-white md:mb-6"
                >
                  Hear from industry experts about the evolving QA Automation
                  industry and get practical career success strategies.
                  Participate in power sessions to get hands-on experience on
                  latest tips & tools for QA Automation. Also get exciting
                  scholarships.
                </p>
                <p className="mb-6 text-white">
                  From 10th June, 2023{' '}
                  <span className="font-bold italic text-[#F2F62E]">
                    Every Sat 12:00 - 2:00 PM
                  </span>
                </p>
              </div>{' '}
              <span className="mb-10">
                <LeadGenerationButton
                  type="Register Now"
                  buttonLocation="CRIO_QA_DESKTOP_HERO"
                  formHeading={'Book your Slot'}
                  pageOneButtonText={'Register Now'}
                  pageTwoButtonText={'Register Now'}
                  applicationPage={'CRIO_QA_FLOW'}
                />
              </span>
            </div>
          </div>
          <div className="flex items-center justify-center p-2">
            <StaticImage
              layout="fullWidth"
              placeholder="blurred"
              src="../../images/crio-reboot/QA-Hero.webp"
              alt="Hero"
              loading="eager"
              className="mb-18 w-full max-w-[400px] md:w-[600px] md:-translate-y-16"
            ></StaticImage>
          </div>
        </div>
      </div>
      <span id="about"></span>
      <div
        className={`${styles.companies} bg-[#F2FBF7] pt-10 pb-16 text-black`}
      >
        <div
          className="container flex flex-col text-center"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <h3 className="mb-10 ">What’s in it for you?</h3>
          <div className="row mb-12">
            <div className="col-12 col-lg-4 flex items-center text-left md:flex-col md:text-center">
              {' '}
              <div className="h-[150px]">
                <StaticImage
                  placeholder="blurred"
                  src="../../images/crio-reboot/QAIcon01.webp"
                  alt="Icon01"
                  loading="lazy"
                  style={{ width: '115px', margin: '20px' }}
                ></StaticImage>
              </div>
              <p className="m-0">
                Deep dive into the automation industry and emerging
                opportunities
              </p>
            </div>{' '}
            <div className="col-12 col-lg-4 flex items-center  text-left md:flex-col md:text-center">
              {' '}
              <div className="h-[150px]">
                <StaticImage
                  placeholder="blurred"
                  src="../../images/crio-reboot/QAIcon02.webp"
                  alt="Icon02"
                  loading="lazy"
                  style={{
                    width: '115px',
                    margin: '20px',
                  }}
                ></StaticImage>
              </div>
              <p className="m-0 pr-2 pl-2">
                Understand the life of an SDET at top product companies
              </p>
            </div>{' '}
            <div className="col-12 col-lg-4 flex items-center  text-left md:flex-col md:text-center">
              {' '}
              <div className="h-[150px]">
                <StaticImage
                  placeholder="blurred"
                  src="../../images/crio-reboot/QAIcon04.webp"
                  alt="Icon04"
                  loading="lazy"
                  style={{ width: '115px', margin: '20px' }}
                ></StaticImage>
              </div>
              <p className="m-0">
                Automation Edge Career Booster pack worth Rs. 2,00,000
              </p>
            </div>
          </div>
          <h3 className="mb-8">And much more…</h3>
          <div className="flex justify-center">
            <LeadGenerationButton
              type="Register Now"
              buttonLocation="CRIO_QA_DESKTOP_HERO"
              formHeading={'Book your Slot'}
              pageOneButtonText={'Register Now'}
              pageTwoButtonText={'Register Now'}
              applicationPage={'CRIO_QA_FLOW'}
            />
          </div>
        </div>
      </div>
      <div
        className={`${styles.forYouSection} bg-[#009378] py-8 text-white md:py-8 md:pb-8`}
      >
        <div
          className="container flex flex-col text-center"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <div className="container mb-4 flex flex-col items-center justify-center gap-2 text-left md:flex-row md:items-start">
            <div className="flex w-1/3 items-center justify-center md:-translate-y-12">
              <StaticImage
                placeholder="blurred"
                src="../../images/crio-reboot/WhoIsThisForImage-QA.webp"
                alt="IconFour"
                loading="lazy"
                style={{ width: '250px' }}
              ></StaticImage>
            </div>
            <div>
              <h3 className="my-4 mt-10 text-center md:text-left">
                Who is this for?
              </h3>
              <ul className="text-lg">
                <li>
                  <p>
                    Professionals looking to switch from Manual to Automation
                    testing
                  </p>
                </li>
                <li className="mt-2">
                  {' '}
                  <p>
                    Automations Engineers looking to crack mid to senior level
                    roles like SDET
                  </p>
                </li>
                <li className="mt-2">
                  {' '}
                  <p>
                    Students in final year who want to build career in
                    automation testing
                  </p>
                </li>
              </ul>
              <div className="mt-8 flex justify-center md:justify-start">
                <LeadGenerationButton
                  type="Register Now"
                  buttonLocation="CRIO_QA_WHO_IS_THIS_FOR"
                  formHeading={'Book your Slot'}
                  pageOneButtonText={'Register Now'}
                  pageTwoButtonText={'Register Now'}
                  applicationPage={'CRIO_QA_FLOW'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="schedule"></span>
      <div className={`${styles.dark} bg-[#F2FBF7] px-4 pt-20 pb-20`}>
        <div className=" mb-5 flex flex-col items-center justify-center">
          <div className="text-left">
            <div className="hidden md:block"></div>
            <div className={`text-left  md:flex-col ${styles.schedule} p-0`}>
              {' '}
              <h3 className="mb-8 text-center">Schedule</h3>
              <div className="container flex flex-col items-center justify-center gap-4 lg:flex-row">
                {scheduleData.map((schedule) => {
                  return (
                    <div className="flex max-w-[350px] flex-1 flex-col self-stretch rounded-md bg-v5-green-300 p-4">
                      <div className="mb-5 flex min-h-[130px] flex-col items-center justify-start gap-2 text-center text-white">
                        <h4 className="text-white">{schedule.title}</h4>
                        <p className="text-sm">{schedule.subTitle}</p>
                        <p className="text-sm">{schedule.description}</p>
                      </div>
                      <div className={styles.scheduleBox}>
                        <h6 className={styles.scheduleBoxHeading}>
                          Date & Duration
                        </h6>{' '}
                        <ul>
                          {schedule.dateAndDuration.map((dateAndDuration) => {
                            return <li>{dateAndDuration}</li>;
                          })}
                        </ul>
                      </div>
                      <div
                        className={`${styles.scheduleBox} !mb-0 min-h-[170px]`}
                      >
                        <h6 className={styles.scheduleBoxHeading}>Speakers</h6>{' '}
                        <ul>
                          {schedule.speakers.map((spearker) => {
                            return <li>{spearker}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>{' '}
          </div>
        </div>{' '}
        <div className="mt-8 flex justify-center">
          <LeadGenerationButton
            type="Register Now"
            buttonLocation="CRIO_QA_SCHEDULE_SECTION"
            formHeading={'Book your Slot'}
            pageOneButtonText={'Register Now'}
            pageTwoButtonText={'Register Now'}
            applicationPage={'CRIO_QA_FLOW'}
          />
        </div>
      </div>{' '}
      <div className={`${styles.companies} bg-[#009378] pt-16 pb-16`}>
        <div className="container flex flex-col text-center">
          <h3 className="mb-6 ">Speakers</h3>
          <p className="mb-16 text-xl">
            Learn the latest trends, get career guidance, and how you can build
            a<br /> successful tech career from industry experts directly
          </p>
          <div className="row mb-5 flex justify-center">
            <div className="mb-4 w-full">
              <SpeakerCarousel images={images} />
            </div>{' '}
          </div>
          <div className="flex items-center justify-center">
            <LeadGenerationButton
              type="Register Now"
              buttonLocation="CRIO_QA_SPEAKERS_SECTION"
              formHeading={'Book your Slot'}
              pageOneButtonText={'Register Now'}
              pageTwoButtonText={'Register Now'}
              applicationPage={'CRIO_QA_FLOW'}
            />
          </div>
        </div>
      </div>{' '}
      <span id="what-you-get"></span>
      <div
        className={`flex flex-col items-center justify-center bg-[#F2FBF7] px-4 pt-20 pb-16 text-center md:text-left`}
      >
        <h2 className="text-2xl md:text-4xl">
          Unlock the Automation Edge Career Booster Pack
        </h2>
        <p className="my-4 text-center text-base md:text-[20px]">
          Participate in the panel discussions and workshops to unlock the
          <br className="hidden md:block" />
          limited-time benefits.{' '}
          <span className="font-bold">(Valid only for first 500 users)</span>
        </p>

        <div className="relative mt-16 rounded-lg bg-v5-green-300 p-8 pt-10 md:w-[860px] md:pt-16">
          <div className="absolute -top-[35px] left-1/2 h-[70px] w-[70px] -translate-x-1/2 md:-top-[50px]  md:h-[100px] md:w-[100px]">
            <StaticImage
              placeholder="blurred"
              src="../../images/automation-edge/GradCap.png"
              loading="lazy"
              className=""
            ></StaticImage>
          </div>
          <p className="py-4 text-center text-xl  font-normal text-white md:text-2xl">
            Automation Edge Booster Pack Worth Rs 1,50,000
          </p>
          <div className="flex flex-col gap-2 text-base text-white md:flex-row md:text-base">
            <p className="rounded-lg bg-v5-green-500 p-4 text-center md:w-[400px]">
              Two 1:1 Premium Mentorship Sessions with mentors from top tech
              companies
            </p>
            <p className="rounded-lg bg-v5-green-500 p-4 text-center  md:w-[400px]">
              Exclusive access to additional electives in Cloud/Dev-ops or ML
              Engineering
            </p>
          </div>
        </div>
        <div className="relative mt-16 w-full rounded-lg  bg-v5-green-300 p-8 pt-10  text-white md:w-[860px] md:pt-16">
          <div className="absolute -top-[35px] left-1/2 h-[70px] w-[70px] -translate-x-1/2 md:-top-[50px]  md:h-[100px] md:w-[100px]">
            <StaticImage
              placeholder="blurred"
              src="../../images/automation-edge/OpenBox.png"
              loading="lazy"
            ></StaticImage>
          </div>
          <p className="py-4 text-center text-xl font-normal md:text-2xl">
            Additional Super Maximum Scholarship of Worth Rs 50,000
          </p>
        </div>
      </div>
      <div className="relative h-[200px] overflow-hidden bg-[#009378] p-4 py-8 md:h-[300px] md:py-4">
        <div className="container flex  h-full items-center justify-center ">
          <div className="row flex flex-col items-center justify-center">
            <h2
              style={{ color: '#fff', fontFamily: 'Manrope' }}
              className="mb-8 text-center text-2xl font-extrabold md:mb-16 md:text-5xl"
            >
              Take your Automation Career to the{' '}
              <br className="hidden md:block" /> next level!
            </h2>
            <LeadGenerationButton
              type="Register Now"
              buttonLocation="CRIO_QA_PRE_FOOTER_SECTION"
              formHeading={'Book your Slot'}
              pageOneButtonText={'Register Now'}
              pageTwoButtonText={'Register Now'}
              applicationPage={'CRIO_QA_FLOW'}
            />
          </div>
        </div>
      </div>
      <FooterV2
        className="bg-[#004246] text-white"
        applicationPage={'CRIO_QA_FLOW'}
      />
    </span>
  );
}

export const pageQuery = graphql`
  query {
    ...CrioRebootFragment
  }
`;

const SpeakerCarousel = (props) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const swiperRef = React.useRef(null);
  const isMobile = useResizer();

  const images = props.images;

  let projects = props.projects || [
    {
      isComingSoon: false,
      title: 'Fireside Chat',
      speakerName: 'Rathinamurthy R',
      companies: (
        <>
          Co founder, Crio.Do,
          <br /> Ex Flipkart
        </>
      ),
      image: 'Rathina',
      logos: [
        { name: 'CrioIcon', url: '' },
        { name: 'FlipkartIcon', url: '' },
      ],
    },
    {
      isComingSoon: false,
      title: 'Success Recipes',
      speakerName: 'Ajitav',
      companies: (
        <>
          Lead SDET (10+ Year Exp.)
          <br />
          Ex- Rakuten, Ex- Google
        </>
      ),
      image: 'Ajitav',
      logos: [
        { name: 'Rakuten', url: '' },
        { name: 'GoogleIcon', url: '' },
      ],
    },
    {
      isComingSoon: false,
      title: 'Expert Panel',
      speakerName: 'Saravana Prabhu',
      companies: (
        <>
          Software Quality Specialist (11+ Year Exp.),
          <br /> Ex- D E Shaw, Ex- Amazon
        </>
      ),
      image: 'Saravana',
      logos: [
        { name: 'DEShawLogo', url: '' },
        { name: 'Amazon_Logo', url: '' },
      ],
    },
  ];

  const SLIDES_PER_VIEW_DESKTOP = 3;
  const settings = {
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: SLIDES_PER_VIEW_DESKTOP,
      },
    },
    id: 'projects-carousel',
    loop: true,
    spaceBetween: 50,
    speed: 300,
    grabCursor: true,
    autoplay: false,
    enabled: projects.length > SLIDES_PER_VIEW_DESKTOP || isMobile,
  };

  const getLogo = (logo, url) => {
    const projectImage = images.find((ele) => ele.name == logo);

    const image = getImage(projectImage?.childImageSharp);

    return (
      <span className="w-[50px]">
        <GatsbyImage
          loading="lazy"
          image={image}
          alt={'company'}
          className=""
        />
      </span>
    );
  };

  return (
    <div className="relative w-full" id="projects-carousel">
      <Swiper
        ref={swiperRef}
        {...settings}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        modules={[Navigation, Autoplay]}
        className={`projectCarousal w-[75vw] md:w-full`}
      >
        {projects.map((project) => {
          const projectImage = images.find((ele) => ele.name == project.image);

          const image = getImage(projectImage?.childImageSharp);

          return (
            <SwiperSlide key={project.title} className="">
              <div
                className={`${styles.speakerCard} h-full min-h-[550px] w-full bg-[#004246]`}
              >
                <div>
                  {project.isComingSoon ? (
                    <div className="flex h-[260px] items-center justify-center bg-[#003639]">
                      <StaticImage
                        placeholder="blurred"
                        src="../../images/crio-reboot/LockIcon.png"
                        alt="Vivek"
                        loading="lazy"
                        style={{ height: '100px', width: '85px' }}
                      ></StaticImage>
                    </div>
                  ) : (
                    <GatsbyImage
                      loading="lazy"
                      image={image}
                      alt={'company'}
                      style={{ width: '100%' }}
                      className=""
                    />
                  )}
                </div>
                <div className={`${styles.speakerCardContent}`}>
                  {project.isComingSoon ? (
                    <h4
                      className={`${styles.speakerTitle} mt-16 min-h-[146px]`}
                    >
                      Coming Soon
                    </h4>
                  ) : (
                    <>
                      <h4 className={styles.speakerTitle}>
                        {project.speakerName}
                      </h4>
                      <p
                        className={`${styles.speakerDescription} min-h-[50px]`}
                      >
                        {project.companies}
                      </p>
                      <div className="mt-5 flex gap-4">
                        {project.logos.map((logo) =>
                          getLogo(logo.name, logo.url),
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>{' '}
            </SwiperSlide>
          );
        })}
      </Swiper>
      {(projects.length > SLIDES_PER_VIEW_DESKTOP || isMobile) && (
        <>
          <div
            onClick={() => swiperRef.current.swiper.slidePrev()}
            ref={navigationPrevRef}
            className="absolute top-1/2 left-[1.5rem] z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer  hover:opacity-75 md:-left-[1.5rem]"
          >
            <FontAwesomeIcon icon={['fas', 'chevron-left']}></FontAwesomeIcon>
          </div>{' '}
          <div
            onClick={() => swiperRef.current.swiper.slideNext()}
            ref={navigationNextRef}
            className="absolute top-1/2 right-[1rem] z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer hover:opacity-75 md:-right-[2rem]"
          >
            <FontAwesomeIcon icon={['fas', 'chevron-right']}></FontAwesomeIcon>
          </div>
        </>
      )}
    </div>
  );
};
